import { Channel, Socket } from "phoenix";
import {
  ParentProps,
  createContext,
  useContext,
  onCleanup,
  createEffect,
  createSignal,
} from "solid-js";
import { HTTPError } from "ky";
import { createQuery } from "@tanstack/solid-query";

import { fetchSocketToken, fetchUser, type User } from "#root/module/api";
import { useHttp } from "#root/domain/http";
import { config, isProduction } from "#root/config";

import { USER_REQUEST_KEY, SOCKET_TOKEN_KEY } from "./cacheKeys";

export const useUser = () => {
  const { getClient } = useHttp();

  const query = createQuery<User, HTTPError>(() => {
    return {
      queryKey: [USER_REQUEST_KEY],
      queryFn: () => {
        const client = getClient();
        return fetchUser(client).then(({ data }) => data);
      },
    };
  });

  return query;
};

type SocketToken = string;
export const useSocketToken = () => {
  const { getClient } = useHttp();

  const query = createQuery<SocketToken, HTTPError>(() => {
    return {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      queryKey: [SOCKET_TOKEN_KEY],
      queryFn: () => {
        const client = getClient();
        return fetchSocketToken(client).then(({ data }) => data.token);
      },
    };
  });

  return query;
};

type UserUpdateEvents = {
  "credits:updated": { data: { value: number } };
};

export const useUserUpdates = () => {
  // Static references
  let socket: Socket | null = null;
  let channel: Channel | null = null;
  let eventListeners: Array<[string, number]> = [];
  const [connected, setConnected] = createSignal<boolean>(false);

  // Dynamic stuff
  const { user, socketToken } = useUserContext();

  function initializeSocket(token: string, userId: string) {
    const newSocket = new Socket(`${config.WS_URL}/socket/account`, {
      params: { token },
      logger(kind, message, data) {
        if (isProduction) return;
        console.debug("[Socket]", "[Account]", kind, message, data);
      },
    });
    newSocket.connect();

    const newChannel = newSocket.channel(`account:${userId}`);
    newChannel.join().receive("ok", () => {
      console.debug("[Socket]", "[Account]", "Connected to channel");
      setConnected(true);
    });
    newChannel.onClose(() => {
      console.debug("[Socket]", "[Account]", "Leaving channel");
      setConnected(false);
    });

    socket = newSocket;
    channel = newChannel;
  }

  function addEventListener<T extends keyof UserUpdateEvents>(
    eventName: T,
    callback: (arg: UserUpdateEvents[T]) => void,
  ) {
    if (!channel) return;

    const listenerId = channel.on(eventName, (e: UserUpdateEvents[T]) => {
      callback(e);
    });

    eventListeners.push([eventName, listenerId]);
  }

  function disconnectSocket() {
    eventListeners.forEach(([eventName, listenerId]) => {
      channel?.off(eventName, listenerId);
    });
    eventListeners = [];
    channel?.leave();
    socket?.disconnect();
  }

  createEffect<boolean>((isConnected) => {
    if (isConnected) {
      disconnectSocket();
    }

    if (socketToken.data && user.data?.id) {
      initializeSocket(socketToken.data, user.data.id);
      return true;
    }

    return false;
  });

  onCleanup(disconnectSocket);

  return {
    // Data
    socket: () => socket,
    channel: () => channel,
    isConnected: connected,

    // Functions
    addEventListener,
  };
};

export const UserContext = createContext<{
  user: ReturnType<typeof useUser>;
  socketToken: ReturnType<typeof useSocketToken>;
}>();

export const UserProvider = (props: ParentProps) => {
  const user = useUser();
  const socketToken = useSocketToken();

  return (
    <UserContext.Provider
      value={{
        user,
        socketToken,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const ctx = useContext(UserContext);
  if (ctx === undefined)
    throw new Error("UserContext must be used inside a provider");
  return ctx;
};

export const useAuthenticatedUser = () => {
  const ctx = useContext(UserContext);

  if (ctx === undefined)
    throw new Error("UserContext must be used inside a provider");

  if (!ctx.user.data)
    throw new Error(
      "useAuthenticatedUser must be used within the /app url segment",
    );

  return ctx.user.data;
};

export function isExperimentalUser(user: User) {
  return config.EXPERIMENTAL_USERS.includes(user.displayName.toLowerCase());
}
